import React, { useRef } from 'react';
// components
import Layout from 'components/layout';
import GetConsultation from 'components/free-consultation';
import Footer from 'components/layout/footer';
import Booking from 'components/feedback';
import { Betone, Torwod } from 'components/carousel';
import loadable from '@loadable/component';
// sections
import Header from 'components/landing-pages/sections/header';
import Features from 'components/landing-pages/sections/features';
import WorkProcess from 'components/landing-pages/sections/work-process';
import Services from 'components/landing-pages/sections/services';
// constants
import { usePageData } from './constants';
// meta
import { meta } from 'metaData';

const SwiperSlider = loadable(() => import('components/swiper-slider'));

const FitnessCenterApp = () => {
  const bookingRef = useRef<HTMLDivElement>(null);

  const pageData = usePageData();

  const handleClickCTA = () => {
    if (bookingRef.current !== null) {
      bookingRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <Layout metaData={{ main: meta.fitnessCenterApp }} navBtnTextColor="var(--black-color)">
      <Header onClickCTA={handleClickCTA} data={pageData.header} hideImg />
      <main style={{ overflow: 'hidden' }}>
        <Features data={pageData.features} />
        <GetConsultation
          title="Looking for custom digital solutions for your fitness business? Schedule a talk with an expert"
          linkText="Contact us"
          onClick={handleClickCTA}
        />
        <Services data={pageData.services} />
        <SwiperSlider>
          <Betone />
          <Torwod />
        </SwiperSlider>
        <WorkProcess data={pageData.workProcess} />
        <GetConsultation
          title="Do you need a user-friendly, engaging, and accessible app?"
          linkText="Build with us!"
          onClick={handleClickCTA}
        />
        <Booking scrollRef={bookingRef} />
      </main>
      <Footer />
    </Layout>
  );
};

export default FitnessCenterApp;
