import React, { FC } from 'react';
// utils
import clsx from 'clsx';
// styles
import styles from './services.module.css';

interface IProps {
  data: {
    title: string;
    subtitle: string;
    list: {
      icon: string;
      title: string;
      subtitle: string;
    }[];
  };
}

const Services: FC<IProps> = ({ data: { title, subtitle, list } }) => (
  <section className={clsx(styles.section, 'inner')}>
    <h2 className="title">{title}</h2>
    <p className={clsx(styles.subtitle, 'subtitle')}>{subtitle}</p>
    <div className={styles.blocksContainer}>
      {list.map(({ title, subtitle, icon }, idx) => (
        <div key={`${title}-${idx}`} className={styles.blockContainer}>
          <div className={styles.blockIcon}>
            <img src={icon} alt="" />
          </div>
          <h3 className={styles.blockTitle}>{title}</h3>
          <p className={styles.blockSubtitle}>{subtitle}</p>
        </div>
      ))}
    </div>
  </section>
);

export default Services;
