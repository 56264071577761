import { graphql, useStaticQuery } from 'gatsby';
// assets
import startupServiceIcon from './assets/startup-service-icon.svg';
import healthServiceIcon from './assets/health-service-icon.svg';
import sportClubServiceIcon from './assets/sport-club-service-icon.svg';

export const usePageData = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "fitness-mobile-header-bg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    header: {
      title: 'Gym software development services',
      text: 'We create digital solutions that help gym owners and trainers expand their business and attract new customers.',
      btnText: 'Get a free consultation',
      bgImg: data.bg.childImageSharp.fluid,
    },
    features: {
      title: 'Solutions We Can Provide for Your Studio or Gym',
      subtitle:
        'Adapt your services and products to the current landscape of the fitness industry.',
      items: [
        {
          title: 'Engage members with activity tracking',
          list: [
            'Show progress to encourage clients to visit more',
            'Collect data from various wearables and sensors',
            'Offer data analysis, visualization, and recommendations',
          ],
        },
        {
          title: 'Motivate people to exercise via gamification',
          list: [
            'Blend gaming elements & serious fitness (e-games, VR, other playful activities)',
            'Add game-like rewards for accomplishments',
          ],
        },
        {
          title: 'Synchronize workouts inside and outside the gym',
          list: [
            'Share outdoor and in-home workouts with members',
            'Synchronize results from gym training and non-gym activities',
          ],
        },
        {
          title: 'Help coaches organize & digitalize their programs',
          list: [
            'Offer remote coaching/mentoring for individuals and fitness groups',
            'Include nutrition advice and food logs',
          ],
        },
        {
          title: 'Connect to smart gym equipment',
          list: [
            'Send usage reports from treadmills, bikes, and other equipment',
            'Help users improve their performance based on their stats',
          ],
        },
        {
          title: 'Manage member relationships and data in gym CRM',
          list: [
            'Automate your business processes to save time and money',
            'Track classes, payments, and membership data in one place',
          ],
        },
      ],
    },
    services: {
      title: 'Who We Can Help',
      subtitle: 'Build your app with Brocoders based on your special requirements:',
      list: [
        {
          title: 'Wellness studios owners',
          subtitle:
            'Bring a holistic wellness experience to your members. Give your clients the digital tools to thrive and maintain both physical and mental wellbeing in new, unconventional ways.',
          icon: startupServiceIcon,
        },
        {
          title: 'Fitness club owners',
          subtitle:
            'You can easily distinguish your club from traditional facilities - make digital experiences an extension of your gym. We’ll build you an app around your client-centric values, convenience, and accessibility.',
          icon: healthServiceIcon,
        },
        {
          title: 'Coaches and mentors',
          subtitle:
            'Create a better product tailored to clients, especially those who are driven to be more aware of their health, fitness, and wellness. Give them access to niche specialists and a sense of belonging to a community.',
          icon: sportClubServiceIcon,
        },
      ],
    },
    workProcess: {
      title: 'How We Work',
      subtitle: 'Here is how we structure our development process:',
      tabs: [
        {
          title: 'Free consultation',
        },
        {
          title: 'Discovery',
        },
        {
          title: 'Integration',
        },
        {
          title: 'Testing',
        },
        {
          title: 'Launch',
        },
        {
          title: 'Support',
        },
      ],
      tabsData: [
        {
          title: 'Our first steps include:',
          list: [
            'Learning about business',
            'Establishing what type of service matches your needs',
            'Drawing up an approximate  timeline',
          ],
        },
        {
          title:
            'Then we need to understand the requirements and expectations for your product by:',
          list: [
            'Conducting in-depth business research',
            'Analyzing your target audience and competition',
            'Defining your product-market fit',
            'Reviewing your existing technologies to see if they suit the API/service policy',
          ],
        },
        {
          title: 'Now is the time to build. The tasks at this stage include:',
          list: [
            'Assembling the team - business analysts, a project manager, designers, developers, QAs',
            'Arranging the development and integration phases',
          ],
        },
        {
          title: 'Once the required functionality is implemented, we begin:',
          list: [
            'Compatibility, security, UI, and performance checks',
            'User testing and collecting feedback',
          ],
        },
        {
          title: 'Let’s publish the project! This includes:',
          list: [
            'Getting the approval to release',
            'Submitting the app and uploading promotional materials to online stores',
          ],
        },
        {
          title: 'We continue working even after your audience starts using the product:',
          list: ['Provide product maintenance and support', 'Plan and implement enhancements'],
        },
      ],
    },
  };
};
